<template>
    <b-modal ref="modal_coversa_padrao"
             :static="true"
             modal-class="modal_coversa_padrao"
             dialog-class="modalInfomacoesKanbanCP"
             content-class="contentModalInfomacoesKanbanCP"
             size="xl"
             v-model="exibe"
             body-class="app-modal-custom-header"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             title="Informações"
             hide-footer
             @hide="hide">

        <div v-if="exibe"
             class="container-fluid modal-painel mx-auto h-90"
             style="margin-bottom:50px;">

            <span id="modal_atendimento">
                &nbsp;#<b>{{dadosConversa.Protocolo}}</b>
            </span>

            <div class="row" id="linha1">

                <div class="col"
                     style="margin-left:15px"
                     id="colunaTabs">

                    <div class="h-100">

                        <b-row id="abasInformacao">
                            <div class="px-4 py-2"
                                 v-for="aba in abas"
                                 :data-id="aba.id"
                                 @click="currentAba = aba.id"
                                 :class="currentAba == aba.id ? 'active' : ''">
                                {{aba.titulo}}
                            </div>
                        </b-row>

                        <b-row id="abasConteudo"
                               class="px-2 py-2 "
                               style="height: calc(90vh - 150px)">
                            <b-col class="h-100">
                                <b-container v-for="aba in abas"
                                             v-if="currentAba == aba.id"
                                             fluid class="py-3 h-100">

                                    <div v-if="aba.id == 1 && dadosConversa.Id"
                                         id="conversaCompleta"
                                         class="h-100 app-scroll-custom">
                                        <Chat ref="chat"
                                              :key="dadosConversa.Id"
                                              :atendimentoId="dadosConversa.Id"
                                              :scrollAutomatico="false"
                                              class="chat-container h-100" />
                                    </div>
                                    <div v-if="aba.id == 2 "
                                         class="h-100">
                                        <div class="app-scroll-custom pt-0 insert_scroll  h-75"
                                             style="overflow-y: scroll;
													border: 1px solid #e8e8e8;
													background: #FFF; ">

                                            <div v-for="(item, index) in observacoes"
                                                 style="background-color: #e8e8e8;
														padding: 10px;
														margin: 10px; ">

                                                <span style="font-size:12px">
                                                    &nbsp;  <b>Criado por:</b> {{item.Usuario.Nome}}											{{formatarData(item.Inclusao)}}
                                                </span><br>
                                                <span v-html="item.Mensagem">&nbsp;&nbsp;</span>
                                            </div><br>
                                        </div>
                                        <div class="caixaObs">
                                            <div id="caixaEditor">
                                                <HtmlEditor ref="editor"
                                                            :tools="editorToolsSet"
                                                            placeholder="Nova observação"
                                                            v-model="texto_obs"
                                                            :class="`chat-editor-2 has-toolbar`" />
                                            </div>
                                        </div>

                                        <span id="btn_salva_obs"
                                              @click="salvar_obs_padrao(dadosConversa)"
                                              style="">
                                            Salvar mensagem
                                        </span>

                                    </div>

                                    <div v-if="aba.id == 3 "
                                         class="h-100">

                                        <div class="app-scroll-custom pt-0 insert_scroll h-100"
                                             id="caixaAtividadeScrollCP"
                                             style="overflow-y: scroll;
													border: 1px solid #e8e8e8;
													background: #FFF;
													overflow-x: hidden; ">
                                            <div id="caixaAtividadeCP"
                                                 class="h-100 w-100">

                                                <div id="menuAtividadeCP">
                                                    <span class="itemMenuAtividadeCP"
                                                          @click="abrirChamandoCP">
                                                        <b-icon icon="arrow90deg-up"
                                                                style="width: 17px;
																	   height: 17px;">
                                                        </b-icon>Abrir atendimento
                                                    </span>

                                                </div>

                                                <div id="menuAgendaAtividadeCP">
                                                    <b-row>
                                                        <b-col cols="4"
                                                               class="mx-3">
                                                            <label for="horaAgendaAtividadeCP">
                                                                Horário:&nbsp;
                                                            </label>
                                                            <input type="time"
                                                                   id="horaAgendaAtividadeCP"
                                                                   class="horaAgendaAtividadeCP"
                                                                   v-model="horaAgendaAtividadeCP" />
                                                            <br><br>
                                                            <label>Usuário:&nbsp;</label>
                                                            <select style="width:70%"
                                                                    v-model="usuarioAgendaAtividadeCP"
                                                                    :disabled="isPerfilMaster">
                                                                <option v-for="(item, i) in usuarios"
                                                                        :value="item.Id">
                                                                    {{item.Nome}}
                                                                </option>
                                                            </select>
                                                            <small class="text-muted">
                                                                *somente usuários master
                                                            </small>
                                                            <span style="bottom:0px;
																		margin-top:40px;
																		display:table">
                                                                Selecionado {{textoCalendario}} as {{horaAgendaAtividadeCP == "" ? '"selecione um horário"':horaAgendaAtividadeCP }} para reativar o atendimento automaticamente
                                                            </span>
                                                        </b-col>

                                                        <b-col>
                                                            <b-calendar block
                                                                        v-model="valorCalendarioAtividadeCP"
                                                                        @context="onContext"
                                                                        locale="pt-BR"
                                                                        style="margin-right:10px">

                                                            </b-calendar>
                                                        </b-col>
                                                    </b-row>

                                                    <b-row>
                                                        <b-col>
                                                            <span class="itemMenuAtividadeCP"
                                                                  style="padding: 5px 45px"
                                                                  @click="salvarAgendamentoCP">
                                                                Salvar
                                                            </span>
                                                        </b-col>
                                                    </b-row>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div v-if="aba.id === 4"
                                         id="aba_obs">
                                        <b-row>
                                            <b-col>
                                                <label :id="'btn-upload' "
                                                       :for="'arquivoCliente' +idCliente "
                                                       style="display: initial;
																font-size: 15px;
																background-color: #19b1bd;
																padding: 10px;
																color:#FFF"
                                                       v-b-tooltip.hover="{ variant: 'success' }"
                                                       title="Upload">
                                                    <i class="fas fa-file-upload"></i>&nbsp; Upload
                                                </label>

                                                <!--<input type="file">-->
                                                <b-form-file v-model="arquivoCliente"
                                                             ref="inputArquivo"
                                                             class="mb-2"
                                                             :id="'arquivoCliente' +idCliente "
                                                             @change="uploadArquivoCP"
                                                             style="display:none">

                                                </b-form-file>
                                            </b-col>

                                        </b-row>

                                        <b-row>
                                            <b-col>
                                                <b-table striped hover
                                                         :items="arquivosCP"
                                                         :fields="fieldsArquivos"
                                                         style="font-size:12px">

                                                    <template #cell(Acao)="data">
                                                        <i class="fas fa-trash-alt float-right ml-2 ArquivoIcons"
                                                           @click="deletarArquivoCP(data)"></i>
                                                        <i class="fas fa-eye float-right ml-2 ArquivoIcons"
                                                           @click="visualizar(data)">
                                                        </i>
                                                    </template>
                                                </b-table>
                                            </b-col>

                                        </b-row>
                                    </div>
                                    <div v-if="aba.id == 5 "
                                         id="aba_historico"
                                         class="h-100">

                                        <div class="app-scroll-custom pt-0 insert_scroll h-100"
                                             id="caixaObservacoesKB"
                                             style="overflow-y: scroll;
													border: 1px solid #e8e8e8;
													background: #FFF; ">

                                            <div v-for="(registro, index) in historico"
                                                 style="background-color: #e8e8e8;
														padding: 10px;
														margin: 10px; ">

                                                <span style="font-size:12px">
                                                    &nbsp;
                                                    <b class="mr-2">
                                                        Criado em: &nbsp;{{registro.Data}}
                                                    </b>
                                                    <span v-html="registro.Descricao"></span>
                                                    <br />
                                                </span>
                                            </div>
                                            <br>
                                        </div>
                                    </div>

                                    <!--PÁGINA TABULAÇÃO  ========================================================-->
                                    <div v-if="aba.id == 6"
                                         id="aba_tabulacao"
                                         class="h-100">
                                        <div class="app-scroll-custom pt-0 insert_scroll h-100 container"
                                             style="overflow-y: scroll; border: 1px solid #e8e8e8; background: #FFF; ">
                                            <div class="tabulac-container">
                                                <b-row v-if="carregandoTabulacoes" style="height: 100%" align-v="center">
                                                    <b-col cols="12" style="text-align:center;">
                                                        <b-spinner></b-spinner>
                                                        <br />
                                                        carregando...
                                                    </b-col>
                                                </b-row>
                                                <b-row v-else-if="camposTabulacao.length == 0" style="height: 100%" align-v="center">
                                                    <b-col cols="12" style="text-align:center;">
                                                        <span>Não há tabulações</span>
                                                    </b-col>
                                                </b-row>

                                                <b-row v-else class="tabulac-row"
                                                       v-for="(campo, index) in camposTabulacao"
                                                       :key="index">
                                                    <b-col cols="12">
                                                        <label class="tabulac-label">{{ campo.Titulo }}</label>
                                                        <input type="text" v-model="campo.Valor" readonly="true" />
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </div>
                                    </div>
                                    <!--PÁGINA CONTATOS  ========================================================-->
                                    <div v-if="aba.id == 7 && (idCliente || dadosConversa.ClienteId)"
                                         id="aba_contatos"
                                         class="h-100 app-scroll-custom pt-0 insert_scroll">
                                        <div id="aba_auxiliar">
                                            <b-col id="caixaContatoPrincipal">
                                                <b-row id="containerContatoPrincipal">
                                                    <br>
                                                    <h5 class="data-field" id="tituloCttPrincipal"><b>Contato Principal</b></h5>
                                                    <b-col class="data-field">
                                                        <label>Nome</label>
                                                        <input type="text" v-model="contatoPrincipal.Nome" disabled>
                                                        <label>Email</label>
                                                        <input type="text" v-model="contatoPrincipal.Email" disabled>
                                                    </b-col>
                                                    <b-col class="data-field">
                                                        <label>Telefone</label>
                                                        <input type="text" v-model="contatoPrincipal.Telefone" disabled>
                                                        <label>Cargo</label>
                                                        <input type="text" v-model="contatoPrincipal.Cargo" disabled>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col class="caixaContatos" v-for="(contato,index) in contatos">
                                                <b-row class="containerContatos">
                                                    <br>
                                                    <h5 class="data-field" id="tituloCttPrincipal"><b>Contato {{ index + 1}}</b></h5>
                                                    <b-col class="data-field">
                                                        <label>Nome</label>
                                                        <input type="text" v-model="contato.Nome" disabled>
                                                        <label>Email</label>
                                                        <input type="text" v-model="contato.Email" disabled>
                                                    </b-col>
                                                    <b-col class="data-field">
                                                        <label>Telefone</label>
                                                        <input type="text" v-model="contato.Telefone" disabled>
                                                        <label>Cargo</label>
                                                        <input type="text" v-model="contato.Cargo" disabled>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </div>
                                    </div>
                                    <b-row v-else-if="aba.id == 7" align-v="center">
                                        <b-col cols="12" style="text-align:center;">
                                            <span>Cliente não vinculado</span>
                                        </b-col>
                                    </b-row>
                                    <!--PÁGINA HISTORICO  ========================================================-->
                                    <div v-if="aba.id == 8" id="aba_historico_conversa" class="h-100 app-scroll-custom pt-0 insert_scroll">
                                        <div class="app-scroll-custom pt-0 insert_scroll h-100 container"
                                             style="overflow-y: scroll; border: 1px solid #e8e8e8; background: #FFF;">
                                            
                                            <b-row style="height: 100%" align-v="center">
                                                <b-col cols="12" style="text-align:center;">
                                                    
                                                        <HistoricoChat :ClienteId="dadosConversa.ClienteId"/>
                                                    
                                                </b-col>
                                            </b-row>
                                        </div>

                                    </div>
                                </b-container>
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div class="col-4"
                     id="colunaDadosCliente">

                    <div id="menu_conv_padrao"
                         v-if="habilitarMenu">
                        <span class="btn_menu_conv_padrao"
                              style="visibility:hidden">
                            Ações
                        </span>
                    </div>

                    <b-row style="height: calc(90vh - 150px)">
                        <b-col class="h-100">
                            <div id="conteudo_cliente"
                                 class="h-100">

                                <div v-if="exibeCliente"
                                     style="background-color: #ededed"
                                     class=" app-scroll-custom pt-0 insert_scroll h-100">

                                    <div id="containerClienteKB"
                                         class="ml-2 mr-2">

                                        <b style="color: var(--cinza-5); margin:10px 0px; display:block">Dados do cliente</b>
                                        <label class="w-100 m-0 h6 font-size-label-input-padrao">
                                            {{ "Nome" }}
                                        </label>
                                        <input type="text" class="input-padrao mt-2" label="Nome" v-model.trim="razaoSocial" />
                                        <label class="w-100 m-0 h6 font-size-label-input-padrao">
                                            {{ "Email" }}
                                        </label>
                                        <input type="text" class="input-padrao mt-2" label="Email" v-model.trim="emailCliente" />
                                        <label class="w-100 m-0 h6 font-size-label-input-padrao">
                                            {{ 	"Telefone" }}
                                        </label>
                                        <input type="text" class="input-padrao mt-2" label="Telefone" v-model.trim="telefoneCliente" />
                                        <label class="w-100 m-0 h6 font-size-label-input-padrao">
                                            {{ "Documento" }}
                                        </label>
                                        <input type="text" class="input-padrao mt-2" label="CNPJ/CPF" v-model.trim="documentoCliente" readonly />
                                        <label class="w-100 m-0 h6 font-size-label-input-padrao">
                                            {{ "Cidade" }}
                                        </label>
                                        <input type="text" class="input-padrao mt-2" label="Cidade" v-model.trim="cidadeCliente" />
                                        <label class="w-100 m-0 h6 font-size-label-input-padrao">
                                            {{ "Estado" }}
                                        </label>
                                        <input type="text" class="input-padrao mt-2" label="Cidade" v-model.trim="estadoCliente" />
                                    </div>
                                    <div id="containerClienteComplementares"
                                         class="ml-2 mr-2">
                                        <div v-for="(dado, i) in camposComplementares">
                                            <label class="w-100 m-0 h6 font-size-label-input-padrao">{{dado.NomeCampo}}</label>
                                            <div v-if="dado.TipoCampo == 3">
                                                <select class="input-padrao mt-2" v-model="dado.RespostaOpcaoId">
                                                    <option v-for="(op, i) in dado.ClientesComplementoOpcao" :value='op.Id'>
                                                        {{op.NomeOpcao}}
                                                    </option>
                                                </select>
                                            </div>
                                            <input v-else type="text" class="input-padrao mt-2" v-model="dado.Resposta" />
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <span>
                                        Cliente não vinculado
                                    </span>
                                </div>
                            </div>

                        </b-col>
                    </b-row>

                </div>
            </div>
        </div>

        <b-overlay :show="loading" rounded="sm" no-wrap>
        </b-overlay>

    </b-modal>
</template>

<script>
    import axios from 'axios'
    import omniApiHost from '@/config/host'
    import * as SDUtils from '@/assets/js/SDUtils'
    import DadosAtendimentoGuiaHistorico from '@/components/atendimento/DadosAtendimentoGuiaHistorico.vue'
    import InputPadrao from '@/components/inputs/InputPadrao.vue';
    import InputSelect from '@/components/inputs/InputSelect.vue';
    import HtmlEditor from "@/components/inputs/HtmlEditor";
    // Services
    import { mapActions, mapGetters } from 'vuex';
    import ClienteServices from '@/assets/js/services/ClienteServices'
    import AtendimentoServices from '@/assets/js/services/AtendimentoServices'
    import moment from "moment"
    import Chat from "@/components/atendimento/Chat";
    import HistoricoChat from "@/components/atendimento/DadosGuiaHistoricoChat";

    export default {
        name: 'ModalConversaPadrao',
        components: {
            ClienteServices,
            DadosAtendimentoGuiaHistorico,
            AtendimentoServices,
            InputPadrao,
            InputSelect,
            Chat,
            HtmlEditor,
            HistoricoChat

        },
        props: {
            dadosConversa: {
                type: Object,
                default() {
                    return {
                        Id: null,
                        Protocolo: null,
                        ClienteId: null
                    };
                }
            },
            onClienteChange: null
        },
        data() {
            return {
                editorToolsSet: "B|I|U|S",
                fieldsArquivos: [{ key: 'Nome', label: "Imagem" }, { key: 'UrlArquivo', label: "Caminho" }, { key: 'Acao', label: "Ação" }],
                estados: [
                    { text: 'Acre', value: 'AC' },
                    { text: 'Alagoas', value: 'AL' },
                    { text: 'Amapá', value: 'AP' },
                    { text: 'Amazonas', value: 'AM' },
                    { text: 'Bahia', value: 'BA' },
                    { text: 'Ceará', value: 'CE' },
                    { text: 'Espírito Santo', value: 'ES' },
                    { text: 'Goiás', value: 'GO' },
                    { text: 'Maranhão', value: 'MA' },
                    { text: 'Mato Grosso', value: 'MT' },
                    { text: 'Mato Grosso do Sul', value: 'MS' },
                    { text: 'Minas Gerais', value: 'MG' },
                    { text: 'Pará', value: 'PA' },
                    { text: 'Paraíba', value: 'PB' },
                    { text: 'Paraná', value: 'PR' },
                    { text: 'Pernambuco', value: 'PE' },
                    { text: 'Piauí', value: 'PI' },
                    { text: 'Rio de Janeiro', value: 'RJ' },
                    { text: 'Rio Grande do Norte', value: 'RN' },
                    { text: 'Rio Grande do Sul', value: 'RS' },
                    { text: 'Rondônia', value: 'RO' },
                    { text: 'Roraima', value: 'RR' },
                    { text: 'Santa Catarina', value: 'SC' },
                    { text: 'Santa Catarina', value: 'SC' },
                    { text: 'São Paulo', value: 'SP' },
                    { text: 'Sergipe', value: 'SE' },
                    { text: 'Tocantins', value: 'TO' },
                    { text: 'Distrito Federal', value: 'DF' },

                ],
                loading: false,
               
                exibeCliente: false,
                exibe: false,
                dados: null,
                historico: null,
                historico_conversa: null,
                observacoes: null,
                arquivosCP: [],
                clienteConversa: {
                    type: Object,
                    default: null
                },
                texto_obs: "",
                agendamentos: [],
                agendamentosAtivos: [],
                atendimentoSelecionado: 0,
                habilitarMenu: false,
                abas: [{ id: 1, titulo: "Conversa" },
                { id: 2, titulo: "Observações" },
                { id: 3, titulo: "Atividade" },
                { id: 4, titulo: "Arquivos" },
                { id: 5, titulo: "Logs" },
                { id: 6, titulo: "Tabulação" },
                { id: 7, titulo: "Contatos" },
                { id: 8, titulo: "Histórico" }],
                currentAba: null,
                conversaPadrao: [],
                conversaPublicaKB: [],
                conversaInternaKB: [],
                camposComplementares: [],
                razaoSocial: '',
                emailCliente: '',
                telefoneCliente: '',
                documentoCliente: '',
                cidadeCliente: '',
                estadoCliente: '',
                buscaComp: null,
                radioAtividadeCP: 'atendimento',
                contextCalendario: '',
                valorCalendarioAtividadeCP: '',
                usuarioAgendaAtividadeCP: '',
                horaAgendaAtividadeCP: '',
                produto: '',
                valor: '',
                textoCalendario: '',
                arquivoCliente: null,
                idCliente: null,
                tabulacoes: {},

                carregandoTabulacoes: false,
                infosTabulacoes: {},
                LabelTabulacoes: [],
                camposTabulacao: [],

                contatos: [],
                contatoPrincipal: {}
            }
        },
        watch: {
            dadosConversa() {
                if (!this.dadosConversa.Id) return;
                this.carregarConversa(this.dadosConversa.Id);
                this.carregarHistorico(this.dadosConversa.Id);
                this.carregarObservacao(this.dadosConversa.Id);
                this.buscaCliente(this.dadosConversa.ClienteId);
                // this.carregaAgendas(this.dadosConversa)
                this.habilitarMenu = true;
                const now = new Date()
                this.valorCalendarioAtividadeCP = moment().format("YYYY-MM-DD");/// now.getFullYear() + "-" + now.getMonth() + "-" + now.getDate();
            },
            currentAba(abaId) {
                if (abaId == 2 && $("#caixaObservacoesKB").get(0)) {
                    setTimeout(() => $("#caixaObservacoesKB").get(0).scrollTo({ top: $("#caixaObservacoesKB").get(0).scrollHeight, behavior: "smooth" }), 100);
                }
                if (abaId == 4) {
                    this.carregarArquivosCP();
                }

                if (abaId == 6) {
                    this.getTabulacoes();
                }
                if (abaId == 7 && (this.idCliente || this.dadosConversa.ClienteId)) {
                    this.buscarContatos(this.idCliente ?? this.dadosConversa.ClienteId);
                }
            }
        },
        created() {
            if (this.dadosConversa) {
                this.carregarConversa(this.dadosConversa.Id);
            }
            this.habilitarMenu = true;
        },
        computed: {
            ...mapGetters({
                usuarios: 'usuarios/listaUsuarios',
                dadosUsuarioAutenticado: 'authentication/authDadosUsuario',
                getUsuarioPorId: 'usuarios/getUsuarioById'
            }),
            usuarioAtivo() {
                if (this.dadosUsuarioAutenticado) return this.getUsuarioPorId(this.dadosUsuarioAutenticado.usuarioId);
                return {};
            },
            isPerfilMaster() {
                return this.usuarioAtivo.Perfil === 'Master' ? true : false;
            }
        },
        methods: {
            ...mapActions({
                mostrarSucesso: 'mostradorDeMensagens/mostrarSucesso',
                mostrarErro: 'mostradorDeMensagens/mostrarErro',
            }),
            buscarContatos(clienteId) {
                axios.get(`api/v2/clientes/buscarcontatos/${clienteId}`).then(response => {
                    this.contatos = response.data.Contatos;
                    var cttPrincipal = response.data.ContatoPrincipal;
                    if (clienteId) {
                        this.contatoPrincipal = {
                            Id: cttPrincipal.Id ?? null,
                            Email: cttPrincipal.Email ?? '',
                            Telefone: cttPrincipal.Telefone ?? '',
                            Nome: cttPrincipal.Nome ?? '',
                            Cargo: cttPrincipal.Cargo ?? '',
                            ContatoPrincipal: true
                        };
                    }
                })
            },
            BuscarConfiguracoes(id) {
                this.camposComplementares = [];
                axios.get(`api/v2/clientes/BuscarConfiguracoes`).then(response => {
                    response.data.forEach(comp => {
                        this.camposComplementares.push({
                            ClientesComplementoOpcao: comp.ClientesComplementoOpcao,
                            NomeCampo: comp.NomeCampo,
                            TipoCampo: comp.TipoCampo,
                            ClientesConfigComplementoId: comp.Id,
                            Resposta: id && this.buscaComp ? this.buscaComp.find(c => c.ClientesConfigComplementoId === comp?.Id)?.Resposta ?? null : null,
                            RespostaOpcaoId: id && this.buscaComp ? this.buscaComp.find(c => c.ClientesConfigComplementoId === comp?.Id)?.RespostaOpcaoId ?? null : null,
                            Id: id && this.buscaComp ? this.buscaComp.find(c => c.ClientesConfigComplementoId === comp?.Id)?.Id ?? null : null
                        });
                    });
                })
            },
            //CONSULTA DA PAGINA DE TABULAÇÕES =====================================================
            getTabulacoes() {
                this.carregandoTabulacoes = true;

                Promise.all([
                    axios.get(`/api/atendimento/${this.dadosConversa.Id}/tabulacoes`).then(response => {
                        this.infosTabulacoes = response.data;
                    }),
                    axios.get("api/tabulacao/BuscaTabulacoes").then(response => {
                        this.LabelTabulacoes = response.data;
                    })
                ]).then(() => {
                    this.camposTabulacao = Object.keys(this.infosTabulacoes).map(tabulacaoId => {
                        const info = this.infosTabulacoes[tabulacaoId];
                        const label = this.LabelTabulacoes.find(label => label.TabulacaoId === tabulacaoId);
                        let valor = info;

                        if (label && label.Tipo === 3) {
                            const listaSuspensaId = label.TabulacaoId;
                            const opcaoListaSuspensaId = this.infosTabulacoes[tabulacaoId];
                            const opcao = label.Itens.find(item => item.ItemId === opcaoListaSuspensaId);


                            if (opcao) {
                                valor = opcao.Titulo;
                            }
                        }
                        return {
                            TabulacaoId: tabulacaoId,
                            Titulo: label ? label.Titulo : '',
                            Valor: valor
                        };

                    });
                    this.carregandoTabulacoes = false

                }).catch(error => {
                    console.error("Erro ao obter tabulações:", error);
                    this.carregandoTabulacoes = false;
                });
            },

            alterarClienteCP: function () {

                var dados = {
                    Id: this.clienteConversa.Id,
                    RazaoSocial: this.razaoSocial,
                    Email: this.emailCliente,
                    Telefone: this.telefoneCliente,
                    CnpjCpf: this.documentoCliente,
                    Municipio: this.cidadeCliente,
                    UF: this.estadoCliente,
                    Produto: this.produto,
                    Valor: this.valor
                }
                ClienteServices.update(dados).then(() => {
                    if (typeof this.onClienteChange == "function") this.onClienteChange();
                    this.$bvToast.toast('Cliente alterado com sucesso', {
                        title: `Aviso`,
                        toaster: 'b-toaster-top-right',
                        solid: true,
                        variant: 'success',
                        appendToast: false,
                        autoHideDelay: 3000,
                        noCloseButton: true
                    })
                });
            },
            formatarData(dataString) {
                return SDUtils.formataJsonData(dataString);
            },
            show() {
                this.horaAgendaAtividadeCP = '';
                this.exibe = true;
                this.$nextTick(() => {
                    this.usuarioAgendaAtividadeCP = this.usuarioAtivo.Id;
                })
            },
            hide() {
                this.dadosConversa.ClienteId = null;
                this.idCliente = null;
                this.contatos = {};
                this.contatoPrincipal = {};
                this.exibe = false
                this.currentAba = 1

            },
            setDados(data) {
                this.dados = data;
            },
            async buscaCliente(id) {
                this.clienteConversa = null;
                this.exibeCliente = false
                if (id != null && id != "00000000-0000-0000-0000-000000000000") {
                    axios.get(`api/Cliente/BuscaIdConversas/${id}`).then(response => {
                        this.clienteConversa = response.data
                        this.razaoSocial = this.clienteConversa.RazaoSocial;
                        this.emailCliente = this.clienteConversa.Email;
                        this.telefoneCliente = this.clienteConversa.Telefone;
                        this.documentoCliente = this.clienteConversa.CnpjCpf;
                        this.cidadeCliente = this.clienteConversa.Municipio;
                        this.estadoCliente = this.clienteConversa.UF;
                        this.produto = this.clienteConversa.Produto;
                        this.valor = this.clienteConversa.Valor;
                        this.idCliente = this.clienteConversa.Id;
                        this.exibeCliente = true;
                        this.BuscarConfiguracoes(id);
                        if (this.clienteConversa.Complementos) {
                            this.buscaComp = this.clienteConversa.Complementos;
                            this.camposComplementares.forEach(campoComp => {
                                campoComp.Resposta = id ? this.buscaComp.find(c => c.ClientesConfigComplementoId === campoComp.ClientesConfigComplementoId)?.Resposta ?? null : null;
                                campoComp.RespostaOpcaoId = id ? this.buscaComp.find(c => c.ClientesConfigComplementoId === campoComp.ClientesConfigComplementoId)?.RespostaOpcaoId ?? null : null;
                                campoComp.Id = id ? this.buscaComp.find(c => c.ClientesConfigComplementoId === campoComp.ClientesConfigComplementoId)?.Id ?? null : null;
                            });
                        }
                    }).catch(error => {
                        console.error(error);
                    })
                }
            },
            carregarArquivosCP() {
                axios.get('/api/discovirtual/carregaImagensAtendimentoDV/' + this.dadosConversa.Id)
                    .then((response) => {
                        $("#caixa_carregamento").show();
                        this.arquivosCP = response.data;
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.error(error);
                        $("#caixa_carregamento").hide()
                    })
            },
            visualisarImagemCP: function (item) {
                window.open(omniApiHost + "/DiscoVirtual" + item, "_blank")
            },
            deletarArquivoCP: function (dados) {
                $("#caixa_carregamento").show();


                axios.post('/api/discovirtual/DeletarArquivo/' + dados.item.MidiaId)
                    .then((response) => {
                        let indexExclusao = this.arquivosCP.findIndex(f => f.MidiaId == dados.item.MidiaId);
                        this.arquivosCP.splice(indexExclusao, 1);
                        this.carregarArquivosCP();
                        $("#caixa_carregamento").hide()

                    }, (error) => {
                        console.error(error);
                        $("#caixa_carregamento").hide()
                    })
            },
            carregarObservacao(idAtendimento) {
                this.observacoes = null;
                axios.get('/api/Atendimento/carregarObservacao/' + idAtendimento)
                    .then((response) => {
                        $("#caixa_carregamento").show();
                        this.observacoes = response.data;
                        if (this.currentAba == 2 && $("#caixaObservacoesKB").get(0)) {
                            setTimeout(() => $("#caixaObservacoesKB").get(0).scrollTo({ top: $("#caixaObservacoesKB").get(0).scrollHeight, behavior: "smooth" }), 100);
                        }

                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.error(error);
                        $("#caixa_carregamento").hide()
                    })

            },
            carregarHistorico(id) {
                axios.get('/api/AtendimentoLog/BuscarHistorico/' + id)
                    .then((response) => {
                        $("#caixa_carregamento").show();
                        this.historico = response.data;
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.error(error);
                        $("#caixa_carregamento").hide()
                    });
            },
            carregarConversa(id) {
                // this.clienteConversa = this.ClienteServices.get
                $("#caixa_carregamento").show();
                axios.get('/api/atendimento/GetConversaPorAtendimento/' + id)
                    .then((response) => {
                        this.clienteAtendimento = response.data.Cliente;
                        this.conversaPadrao = response.data.Conversa;
                        $("#caixa_carregamento").hide();
                        return;
                    }, (error) => {
                        console.error(error);
                        $("#caixa_carregamento").hide()
                    });

                axios.get(`/api/atendimento/${this.dadosConversa.Id}/tabulacoes`).then(response => {
                    this.tabulacoes = response.data;
                    $("#caixa_carregamento").hide();
                }).catch(() => {
                    console.error("Houve um erro na consulta de tabulações do atendimento.");
                    $("#caixa_carregamento").hide();
                });
            },
            gerarConversaCompleta: function (conversa) {
                this.conversaPadrao.push(conversa);
            },
            gerarConversaPublica: function (conversa) {
                this.conversaPublicaKB.push(conversa);
            },
            gerarConversaInterna: function (conversa) {
                this.conversaInternaKB.push(conversa);
            },
            salvar_obs_padrao: function (valor) {
                var dados = {
                    Mensagem: this.texto_obs,
                    AtendimentoId: valor.Id,
                    OrigemContato: "Observação Kanban",
                    Acao: 4,
                };
                axios.post('/api/atendimento/SalvaMensagemPadrao/', dados)
                    .then((response) => {

                        this.carregarObservacao(valor.Id)

                        setTimeout(function () { $("#avisos").text("") }, 2000);
                    }, (error) => {
                        console.error(error);

                        $("#carregamento_animado").hide();
                    });

                this.texto_obs = ''
            },
            agendar_pergunta_padrao: function () {

                if (this.dadosConversa) {
                    this.$refs.modal_agendamento.limpa()
                    this.$refs.modal_agendamento.show()
                }
            },
            onContext(ctx) {
                this.contextCalendario = ctx;
                this.textoCalendario = this.contextCalendario.activeFormatted;

            },
            salvarAgendamentoCP: function () {
                if (this.horaAgendaAtividadeCP == '') {
                    this.mostrarErro("Você precisa escolher um horario")
                    return
                }
                const usuarioSelecionado = this.usuarios.filter(f => f.Id == this.usuarioAgendaAtividadeCP);
                const dataAgendada = this.contextCalendario.selectedYMD + " " + this.horaAgendaAtividadeCP;

                axios.put(`/api/atendimento/${this.dadosConversa.Id}/agendar`, {
                    DataAgendamento: dataAgendada,
                    UsuarioId: usuarioSelecionado[0].Id
                }).then(() => {
                    this.$bvToast.toast('Agendamento realizado com sucesso!', {
                        title: `Aviso`,
                        toaster: 'b-toaster-top-center',
                        solid: true,
                        variant: 'success',
                        appendToast: false,
                        autoHideDelay: 3000,
                        noCloseButton: true
                    })
                })
            },
            abrirChamandoCP: function () {
                if (!this.usuarioAgendaAtividadeCP) {
                    alert("Selecione um usuário para este atendimento")
                    return
                }

                const usuarioSelecionado = this.usuarios.find(f => f.Id === this.usuarioAgendaAtividadeCP);

                this.loading = true;
                AtendimentoServices.reativarAtendimentoPorId(this.dadosConversa.Id, usuarioSelecionado?.Id)
                    .then(() => {
                        this.loading = false;
                        this.$bvToast.toast('O ticket foi reaberto', {
                            title: `Aviso`,
                            toaster: 'b-toaster-top-center',
                            solid: true,
                            variant: 'success',
                            appendToast: false,
                            autoHideDelay: 5000,
                            noCloseButton: false
                        })
                    })
                    .catch(err => {
                        this.loading = false;
                        this.mostrarErro(err.message)
                    })
            },
            uploadArquivoCP: async function (event) {
                let formData = new FormData();
                formData.append('arquivo', event.target.files[0]);
                return await axios.post(`api/atendimento/${this.dadosConversa.Id}/criar-anexo`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then((response) => {
                    this.arquivosCP.push(response.data)

                }).catch((r) => {

                    this.$bvToast.toast(`Houve um erro ao tentar salvar o anexo "${formData.nome}".`, {
                        title: "Ops, algo deu errado...",
                        toaster: "b-toaster-top-right",
                        variant: "danger",
                        solid: true,
                        autoHideDelay: 5000
                    });
                });
            },

            visualizar(dados) {

                window.open(dados.item.UrlArquivo, '_blank');
            },
           
        },

        created() {
            this.currentAba = 1;
        }
    }
</script>
<style scoped>
	.caixaObs{
		width:100%; 
		padding: 0; 
		position: relative; 
		bottom:-10px;
	}

    #aba_contatos {
        padding-right: 0;
    }

    #aba_auxiliar {
        padding-right: 15px;
    }

    #caixaContatoPrincipal {
        background-color: #FFF;
        border-radius: 25px;
        padding: 0 30px 30px 30px;
        margin-bottom: 3vh;
    }

    .caixaContatos {
        background-color: #FFF;
        border-radius: 25px;
        padding: 0 30px 30px 30px;
        margin-bottom: 3vh;
    }

    #tituloCttPrincipal {
        margin-left: 15px;
    }

    #containerClienteComplementares {
        margin-bottom: 1rem;
    }

    .input-padrao {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
    }



        .input-padrao:hover, .input-padrao:focus {
            outline: none;
        }

    .font-size-label-input-padrao {
        font-size: 12px;
        text-align: start !important;
    }

    input[type=text]::-webkit-calendar-picker-indicator {
        opacity: 0;
    }

    #menuAtividadeCP {
        display: table;
        width: 100%;
    }

    .radioAtividadeCP > label {
        background-color: transparent;
        border-radius: 0px;
        color: var(--cinza-5);
        margin: 10px;
    }

        .radioAtividadeCP > label.btn.btn-secondary.active {
            background-color: #19b1bd;
            color: #FFF;
            outline: none;
            box-shadow: 0 0 0 0 rgb(130 138 145);
        }

    .itemMenuAtividadeCP {
        background-color: #19b1bd;
        color: #FFF;
        padding: 5px;
        float: right;
        margin: 10px;
        cursor: pointer;
    }

        .itemMenuAtividadeCP:hover {
            filter: brightness(0.9)
        }

    .bnt_alterarDadosCP {
        background-color: #88A4CA;
        color: #FFF;
        padding: 6px;
        display: table;
        float: right;
        margin: 25px 0px;
        cursor: pointer;
    }

    .radioAtividadeCP > label.btn.btn-secondary.active:hover, .radioAtividadeCP > label.btn.btn-secondary.active:focus {
        outline: none;
        box-shadow: 0 0 0 0 rgb(130 138 145);
    }

    .bnt_alterarDadosCP:hover {
        background-color: var(--cinza-5)
    }

    .container_conversa {
        display: table;
        width: 100%;
        font-size: 12px;
    }

    .container_caixa_conversa {
        display: table;
        width: 60%;
        margin-left: 10px;
    }

    .caixa_conversa {
        display: table;
        min-width: 202px;
        min-height: 50px;
        background-color: #e4e4e4;
        margin: 10px;
        border-radius: 10px 10px 0px 10px;
        padding: 2%
    }

    .container_caixa_conversa_minha {
        display: table;
        width: 60%;
        float: right;
        margin: 2%;
    }

    .caixa_conversa_minha {
        display: table;
        min-width: 202px;
        min-height: 50px;
        background-color: #c6c6c6;
        margin: 10px;
        border-radius: 10px 10px 0px 10px;
        padding: 2%
    }

    .ajuste_msg {
        padding: 2%;
        display: block;
        text-align: left;
    }

    .texto_min {
        font-size: 10px;
        font-weight: bold;
    }

    .topo_cor {
        padding: 1%;
        border-radius: 6px;
        width: 30%;
        display: table;
        text-align: center;
        float: right;
    }

    .footer_data {
        float: right
    }

    /*ABA TABULAÇÃO*/

    .tabulac-container {
        padding: 20px 10px 10px;
    }

    .tabulac-label {
        margin-bottom: 0;
    }

    .tabulac-row input {
        width: 100%;
    }

    .tabulac-row {
        margin-bottom: 2%
    }

    .abrirChamandoInicio {
        padding: 11px;
        cursor: pointer;
    }

        .abrirChamandoInicio:hover {
            text-decoration: underline;
        }

    input, select {
        border: 1px var(--cinza-4) solid;
        background-color: var(--cinza-2);
        font-size: 14px;
        padding: 5px 7px;
        color: var(--cinza-5);
        outline-style: none;
        transition: all ease-in-out .3s;
    }

        input:focus, select:focus {
            filter: brightness(1.03);
        }

    #abasInformacao > div {
        background-color: #f2f2f2;
        cursor: pointer;
        color: #7d7d7d;
        border-right: 1px #e8e8e8 solid;
        margin-right: 5px;
    }

        #abasInformacao > div.active {
            background-color: #e8e8e8;
            cursor: default;
            font-weight: 600;
        }

    #abasConteudo {
        background-color: #e8e8e8;
        cursor: default;
    }

    #historico_atendimento {
        border: 1px solid #E8E8E8;
        max-height: 342px;
        height: 342px;
        overflow: scroll;
        font-size: 12px;
        background-color: #e4e4e4;
        margin-top: 20px;
    }

    .modal-estatistica {
        background-color: transparent;
    }


    .estilo-panel {
        background-color: #f5f5f5;
        box-shadow: 5px 5px 5px #ccc;
    }

    .modal-titulo {
        color: #88A4CA;
        font-weight: 600;
        font-size: 20px;
    }

    .linha_par {
        background-color: #E8E8E8;
    }

    .btn_menu_conv_padrao {
        color: #FFF;
        padding: 5px;
        background-color: #88A4CA;
        cursor: pointer;
        width: 72px;
        float: right;
        margin-bottom: 6px;
        text-align: center;
    }

        .btn_menu_conv_padrao:hover {
            background-color: var(--cinza-5)
        }

    #menu_conv_padrao {
        display: flow-root;
    }

    #lstConversa, #lstConversaInterna, #lstConversaPublica, #lstObs {
        border: 1px solid #FFF;
    }

    .container-fluid {
        padding-right: 0px;
        padding-left: 0px;
    }

    #textarea-rows {
        width: 100%;
    }

    #btn_salva_obs {
        background-color: #19b1bd;
        color: #FFF;
        width: 100%;
        cursor: pointer;
        text-align: center;
        padding: 7px;
        display: block;
        position: relative;
        bottom: 0px;
    }

    .mensagem-imagem {
        max-width: 300px;
    }

        .mensagem-imagem > img {
            max-width: 300px;
            cursor: pointer;
            margin-bottom: 5px;
        }

    .caixa-arquivo {
        width: 300px;
        background-color: rgba(255, 255, 255, .25);
        padding: 10px 14px;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 5px;
    }

        .caixa-arquivo > i.fas {
            font-size: 30px;
        }

            .caixa-arquivo > i.fas.fa-arrow-alt-circle-down {
                font-weight: 500;
                color: var(--cinza-5);
            }

        .caixa-arquivo > span {
            width: 186px;
            font-size: 14px;
        }

    .chat-container {
        background-color: #fff;
        padding: 0 50px;
    }

    .ArquivoIcons {
        padding: 0px 5px;
        cursor: pointer;
    }
</style>

<style>
	.modalInfomacoesKanbanCP {
		display: flex;
		height: 90vh;
		max-width: 97% !important;
	}

	.contentModalInfomacoesKanbanCP {
		height: 100%;
		width: 98%;
		margin: 0px auto;
	}

	.chat-editor-2.has-toolbar.html-editor > div[contenteditable] {
		margin-top: 6px !important;
		max-height: 70px !important;
		min-height: 70px;
	}

	.chat-editor-2.html-editor {
		display: flex;
		flex-direction: column-reverse;
		margin-bottom: 30px;
	}

		.chat-editor-2.html-editor > div[contenteditable] {
			flex-grow: 1;
			max-height: 30vh;
			overflow-y: auto;
			scrollbar-width: thin;
			scrollbar-color: grey lightgrey;
		}

			.chat-editor-2.html-editor > div[contenteditable]::-webkit-scrollbar-track {
				background: lightgrey;
				border-radius: 0.15em;
			}

			.chat-editor-2.html-editor > div[contenteditable]::-webkit-scrollbar-thumb {
				background: grey;
				border-radius: 0.15em;
			}

			.chat-editor-2.html-editor > div[contenteditable]::-webkit-scrollbar {
				width: .5em;
				height: .5em;
			}

		.chat-editor-2.html-editor > .placeholder {
			top: 0;
		}

	.chat-editor-2.has-toolbar.html-editor > div[contenteditable] {
		margin-top: 6px;
	}

	.chat-editor-2.has-toolbar.html-editor > .placeholder {
		top: 34px;
		height: auto;
	}

	.chat-editor-2.has-toolbar.html-editor > .html-editor-tools {
		position: relative;
		display: block;
		background-color: transparent;
		margin-top: 0;
		box-shadow: none;
		border-bottom: 1px var(--cinza-4) solid;
		padding-top: 0;
		z-index: auto;
	}

		.chat-editor-2.has-toolbar.html-editor > .html-editor-tools > i {
			background-color: transparent;
			margin-right: 3px;
			cursor: pointer;
		}

			.chat-editor-2.has-toolbar.html-editor > .html-editor-tools > i.active {
				background-color: var(--cor-primaria-cliente);
				color: var(--cinza-1);
			}
</style>