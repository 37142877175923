import axios from 'axios'
const URL_API = '/api'
import LogServices from '@/assets/js/services/LogServices'

const getAll = async (dataInicial = null, dataFinal = null) => {
    try {
        const { data } = await axios.get(`${URL_API}/Cliente`, {
            params: {
                'DataInicial': dataInicial,
                'DataFinal': dataFinal
            }
        })
       
        return data
    } catch (e) {
        LogServices.logErro(e, 'getAllCliente')
        throw(e)
    }
}
const clientesPadrao = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/Cliente/clientesPadrao`)

        return data
    } catch (e) {
        LogServices.logErro(e, 'clientesPadrao')
        throw (e)
    }
}
const getById = async (idCliente, tabelas = null) => {
    try {
        const tabelasIncluidas = tabelas ? `?tabelas=${tabelas}` : ''
        const { data } = await axios.get(`${URL_API}/Cliente/${idCliente}${tabelasIncluidas}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'getByIdCliente')
        throw(e)
    }
}

const post = async (cliente) => {
    try {
        const { data } = await axios.post(`${URL_API}/Cliente`, cliente)
       
        return data
    } catch (e) {
        LogServices.logErro(e, 'postCliente')
        throw(e)
    }
}
const ClienteWhatsDireto = async (cliente) => {
    try {
        const { data } = await axios.post(`${URL_API}/Cliente/ClienteWhatsDireto`, cliente)

        return data
    } catch (e) {
        LogServices.logErro(e, 'ClienteWhatsDireto')
        throw (e)
    }
}

const update = async (cliente) => {
    try {
        const { data } = await axios.put(`${URL_API}/Cliente/${cliente.Id}`, cliente)
        return data
    } catch (e) {
        LogServices.logErro(e, 'updateCliente')
        throw(e)
    }
}

const updateContato = async (contato) => {
    try {
        const { data } = await axios.put(`${URL_API}/Cliente/contato`, contato)
        return data
    } catch (e) {
        LogServices.logErro(e, 'updateContato')
        throw (e)
    }
}

const addPagamento = async (lancamento) => {
    try {
        const { data } = await axios.post(`${URL_API}/Cliente/${lancamento.ClienteId}/pagamento`, lancamento)
        return data
    } catch (e) {
        LogServices.logErro(e, 'updateContato')
        throw (e)
    }
}

const updatePagamento = async (clienteId, lancamento) => {
    try {
        const { data } = await axios.put(`${URL_API}/Cliente/${clienteId}/pagamento`, lancamento)
        return data
    } catch (e) {
        LogServices.logErro(e, 'updateContato')
        throw (e)
    }
}

const deleteById = async (id) => {
    try {
        const { data } = await axios.delete(`${URL_API}/Cliente/${id}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'deleteCliente')
        throw(e)
    }
}

const deleteContatoById = async (contatoId) => {
    try {
        const { data } = await axios.delete(`${URL_API}/Cliente/contato/${contatoId}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'deleteContato')
        throw (e)
    }
}

const buscarClientesComFiltros = async ({nomeFiltrado, ordenacao = 'DESC', paginaAtual, tamanhoPagina}) => {
    try {
        console.log(nomeFiltrado)
        const { data } = await axios.get(`${URL_API}/Cliente/buscarClientesComFiltros`, {
            params: {
                'NomeFantasia': nomeFiltrado,
                'Ordenacao': ordenacao,
                'PaginaAtual': paginaAtual,
                'TamanhoPagina': tamanhoPagina,
            }
        })
        return data
    } catch (e) {
        console.log('erro getPaginaClientes', e)
        throw (e)
    }
}

const buscaRapida = async ({ cnpjCpf, email, telefone, nomeFantasia, razaoSocial, nomeContato }) => {
    try {
        const { data } = await axios.get(`${URL_API}/Cliente/busca-rapida`, {
            params: {
                'CnpjCpf': cnpjCpf,
                'Email': email,
                'Telefone': telefone,
                'NomeFantasia': nomeFantasia,
                'RazaoSocial': razaoSocial,
                'NomeContato': nomeContato,
            }
        })
        return data
    } catch (e) {
        console.log('erro buscaRapida', e)
        throw (e)
    }
}
const buscaVinculo = async ({ cnpjCpf, email, telefone, nomeFantasia, razaoSocial, nomeContato }) => {
    try {
        const { data } = await axios.get(`${URL_API}/Cliente/buscaVinculo`, {
            params: {
                'CnpjCpf': cnpjCpf,
                'Email': email,
                'Telefone': telefone,
                'NomeFantasia': nomeFantasia,
                'RazaoSocial': razaoSocial,
                'NomeContato': nomeContato,
            }
        })
        return data
    } catch (e) {
        console.log('erro buscaRapida', e)
        throw (e)
    }
}

const vincularClienteAtendimento = async (clienteId, atendimentoId) => {
    try {
        const vinculoClienteAtendimento = {
            'ClienteId': clienteId,
            'AtendimentoId': atendimentoId
        }
        const { data } = await axios.post(`${URL_API}/Cliente/vinculo-atendimento`, vinculoClienteAtendimento)
        return data
    } catch (e) {
        console.log('erro getClientesByFiltros', e)
        throw (e)
    }
}

const importarClientes = async (dataClients) => {
    try {
        const response = await axios.post(`${URL_API}/Cliente/importarClientes`, dataClients);
        return response ? response.data : null;
    } catch (e) {
        LogServices.logErro(e, 'importarClientes');
        throw (e);
    }
}

export default {
    getAll,
    getById,
    post,
    update,
    updateContato,
    addPagamento,
    updatePagamento,
    deleteById,
    deleteContatoById,
    buscarClientesComFiltros,
    buscaRapida,
    vincularClienteAtendimento,
    importarClientes,
    buscaVinculo,
    clientesPadrao,
    ClienteWhatsDireto
}
