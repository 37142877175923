class SDAudioRecorder {
	#mediaStream = null;
	#mediaRecorder = null;
	#audioData = [];

	async start() {
		this.#mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });

		this.#mediaRecorder = new MediaRecorder(this.#mediaStream, { mimeType: "audio/webm" });

		this.#mediaRecorder.ondataavailable = e => this.#audioData.push(e.data);

		this.#mediaRecorder.start(200);
	}
	async resume() {
		if (this.#mediaRecorder && this.#mediaRecorder.state == "paused") this.#mediaRecorder.resume();
	}
	async pause() {
		if (this.#mediaRecorder && this.#mediaRecorder.state == "recording") this.#mediaRecorder.pause();
	}
	async stop() {
		if (!this.#mediaRecorder) return;

		if (this.#mediaRecorder.state != "inactive") {
			this.#mediaRecorder.stop();
			this.#mediaStream.getTracks().forEach(track => track.stop());
		}

		this.#mediaStream = null;
		this.#mediaRecorder = null;
	}

	get audioObject() {
		return new Blob(this.#audioData, { type: "audio/webm" });
	}

	get audioSrc() {
		if (!this.audioObject) return null;
		return window.URL.createObjectURL(this.audioObject);
	}
}

export default SDAudioRecorder;