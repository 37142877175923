<template>
    <div class="dados-atendimento-guia-historico">

        <div
            class="container-fluid"
            style="color: #535353;"
            v-for="(registro, index) in historico"
            :key="index"
        >
            <div class="row">
                <div class="col-12">
                    <b> {{ registro.Descricao }} </b>
                </div>
            </div>
            <div class="row" style="font-size:small">

                <div class="col-12">
                    {{ registro.InclusaoFormatada }}
                    {{ registro.Usuario? `( ${registro.Usuario.Nome} )`: ''  }}
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'DadosAtendimentoGuiaHistorico',

    components: {
    },

    props: {
        historico: {
            type: Array,
            default: () => []
        },
    },

    data() {
        return {
        }
    },

    methods: {
    }
}

</script>

<style scoped>


</style>